















import { Component, Vue } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { Route } from "vue-router";
import MobileHomeNavigationView from "./MobileHomeNavigationView.vue";
import EventManager from "@/utilities/EventManager";
import ScreenUtil from "@/utilities/ScreenUtil";

const MobileMainView = () => import("./main/MobileMainView.vue");
const MobilePaymentInfoView = () => import("./payment/MobilePaymentInfoView.vue");
const MobilePaymentListView = () => import("./payment/MobilePaymentListView.vue");
const MobileTransferInfoView = () => import("./transfer/MobileTransferInfoView.vue");
const MobileTransferListView = () => import("./transfer/MobileTransferListView.vue");
const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");

class MobileHomeProperties {
    events = new EventManager();
}

export { MobileHomeProperties };

@Component({
    components: { MobileHomeNavigationView, AlertDialogView },
    data: () => ({
        tab: null,
    }),
    computed: {
        screenSmall: function() {
            return this.$vuetify.breakpoint.smAndDown;
        },
        screenMedium: function() {
            return this.$vuetify.breakpoint.mdAndDown;
        }
    }
})
export default class MobileHomeView extends Vue {
    private properties = new MobileHomeProperties();
    private alertDialog = new AlertDialogProperties();
    private component: any;

    public async created() {
        if (!ScreenUtil.screenMedium(this.$vuetify.breakpoint)) {
            const path = this.$route.fullPath;
            await this.$router.replace(path.substring(2));
        } else {
            this.$router.beforeEach(async (to, from, next) => {
                await this.pathChanged(to);
                next();
            });

            this.pathChanged(this.$route);
            window.addEventListener("resize", this.windowResized);
        }
    }

    public destroyed() {
        window.removeEventListener("resize", this.windowResized);
    }

    public async windowResized() {
        if (!ScreenUtil.screenMedium(this.$vuetify.breakpoint)) {
            window.removeEventListener("resize", this.windowResized);
            const path = this.$route.fullPath;
            await this.$router.replace(path.substring(2));
        }
    }

    public async pathChanged(route: Route) {
        const path = route.path;
        const s = path.split('/');

        this.component = null;
        this.$forceUpdate();
        await Vue.nextTick();

        if (path === "/m/home") {
            this.component = MobileMainView;
            this.$forceUpdate();
        } else if (path === "/m/payment" || (s[1] === 'm' && s[2] === 'payment') ||
            path === "/m/processing" || (s[1] === 'm' && s[2] === 'processing')) {
            if (route.params.uniqueNo) {
                this.component = MobilePaymentInfoView;
            } else {
                this.component = MobilePaymentListView;
            }
            this.$forceUpdate();
        } else if (path === "/m/transfer" || (s[1] === 'm' && s[2] === 'transfer')) {
            if (route.params.uniqueNo) {
                this.component = MobileTransferInfoView;
            } else {
                this.component = MobileTransferListView;
            }
            this.$forceUpdate();
        }
    }

    public async alertDialogClicked(button: string) {
        this.alertDialog.visible = false;
        await this.properties.events.fire('alert-dialog-clicked', button);
        await this.alertDialogClosed();
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }
        await this.properties.events.fire('alert-dialog-closed');

        const tag = this.alertDialog.tag;
        if (tag && tag.command === "load-error") {
            const redirect = tag.redirect;
            if (redirect) {
                await this.$router.push(redirect);
            }
        }
    }
}
