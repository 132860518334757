












































import { Vue, Component, Prop } from "vue-property-decorator";
import CompanyService from "@/services/CompanyService";

@Component({
    data: () => ({
        drawer: false,
        title: ""
    })
})
export default class MobileHomeNavigationView extends Vue {
    private companyService = new CompanyService();

    public async created() {
        await this.loadCompany();
        window.hamburger = this.hamburger;
    }

    public destroyed() {
        window.hamburger = null;
    }

    public async loadCompany() {
        try {
            const r = await this.companyService.summary();
            document.title = r.data.name;
            this.$data.title = document.title;
        } catch (e) {
            document.title = this.$t('title.app').toString();
            this.$data.title = document.title;
        }
    }

    public hamburger() {
        this.$data.drawer = !(this.$data.drawer ?? false);
    }
}
